<template>
  <b-card
    :title="title"
    :sub-title="subtitle"
    header-bg-variant="dark"
    header-text-variant="white"
  >
    <b-card-text>
      <b-row>
        <b-col>Total em USDT:</b-col>
        <b-col>{{ moneyFormat(totalValueUSD) }}</b-col>
      </b-row>
      <b-row>
        <b-col>Contagem:</b-col>
        <b-col>{{ count }}</b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    totalValueUSD: Number,
    count: Number,
  },
};
</script>
